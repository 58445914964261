
/* This file is used to overwrite the Bootstrap colors, font, and styling */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

$body-bg: rgb(31, 31, 31);


* {
    border-radius: 0.1 !important;
    font-family: 'Roboto Condensed', sans-serif; 
};

$theme-colors: (
    "primary":  #008cab,
    "ultramarine":#003e51,
    "ultrahot": #d90053,
    "danger": #d90053,
    "secondary": #474e52,
    "superdark": #353535,
    "dark": 	#292b2c,
    "info": rgb(56, 134, 141),
    "text-white": #15576b,
    "white":#ffff,
);



@import "node_modules/bootstrap/scss/bootstrap";